
import XLSX from "xlsx";

// excel file转json
export function excel2Json(file) {
  return new Promise(function (resolve, reject) {

    var excelData = [];
    var binary = "";
    var rABS = false; //是否将文件读取为二进制字符串
    var workbook; //读取完成的数据
    let reader = new FileReader();

    reader.onload = function () {
      try {
        var bytes = new Uint8Array(reader.result);
        var length = bytes.byteLength;
        for (var i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        if (rABS) {
          workbook = XLSX.read(btoa(fixdata(binary)), {
            //手动转化
            type: "base64"
          });
        } else {
          workbook = XLSX.read(binary, {
            type: "binary"
          });
        }
      } catch (e) {
        console.log("文件类型不正确");
        console.log(e);
        reject('文件类型不正确')
        return;
      }
      var fromTo = "";
      for (var sheet in workbook.Sheets) {
        if (workbook.Sheets.hasOwnProperty(sheet)) {
          fromTo = workbook.Sheets[sheet]["!ref"];
          excelData = excelData.concat(
            XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
          );
        }
      }
      console.log(JSON.stringify(excelData));

      resolve(excelData)
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export function excelDateToJSDate(serial) {
  var utc_days  = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}
