<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入箱子名称/锁地址" style="width: 220px;" class="filter-item" clearable/>
      <el-select class="filter-item" v-model="listQuery.device_id" placeholder="请选择设备" style="width: 140px" clearable>
        <el-option v-for="item in devices" :key="item.device_id" :label="item.device_address_and_name" :value="item.device_id"></el-option>
      </el-select>
      <el-select class="filter-item" v-model="listQuery.is_active" placeholder="请选择状态" style="width: 140px" clearable>
        <el-option label="正常" :value="1"></el-option>
        <el-option label="禁用" :value="0"></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleAdd">添加</el-button>
      <el-upload :disabled="importBtnLoading" class="upload-demo" style="float:right" action="" :show-file-list="false" :auto-upload="false" :on-change="handleImport">
        <el-button :loading="importBtnLoading" class="filter-item" type="primary" icon="el-icon-upload">模板导入</el-button>
      </el-upload>
      <el-button class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload" style="float:right">模板下载</el-button>
    </div>
    <div class="filter-container">
      <el-button-group>
        <el-button type="primary" size="small" @click="handleBatchOption('open', '')" :disabled="ids.length <= 0">批量开箱</el-button>
        <el-button type="success" size="small" @click="handleBatchOption('heat', '1')" :disabled="ids.length <= 0">批量加热</el-button>
        <el-button type="success" size="small" @click="handleBatchOption('heat', '0')" :disabled="ids.length <= 0">批量停止加热</el-button>
        <el-button type="info" size="small" @click="handleBatchOption('lamp', '1')" :disabled="ids.length <= 0">批量消毒</el-button>
        <el-button type="info" size="small" @click="handleBatchOption('lamp', '0')" :disabled="ids.length <= 0">批量停止消毒</el-button>
        <el-button type="warning" size="small" @click="handleBatchOption('light', '1')" :disabled="ids.length <= 0">批量开灯</el-button>
        <el-button type="warning" size="small" @click="handleBatchOption('light', '0')" :disabled="ids.length <= 0">批量关灯</el-button>
        <el-button type="primary" size="small" @click="handleBatchOption('use', 0)" :disabled="ids.length <= 0">批量空闲</el-button>
      </el-button-group>
    </div>
    <el-table v-loading="listLoading" :data="list" @selection-change="handleSelectionChange" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column type="selection" align="center" width="40"></el-table-column>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school.school_name }}
        </template>
      </el-table-column>
      <el-table-column label="设备" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.device.device_address_and_name }}
        </template>
      </el-table-column>
      <el-table-column label="设备编号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.device_id }}
        </template>
      </el-table-column>
      <el-table-column label="箱子名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.box_name }}
        </template>
      </el-table-column>
      <el-table-column label="锁地址" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.lock_id }}
        </template>
      </el-table-column>
      <el-table-column label="箱子大小" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.box_size == 'S'">小箱</span>
          <span v-else-if="scope.row.box_size == 'L'">大箱</span>
        </template>
      </el-table-column>
      <el-table-column label="禁用状态" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.is_active == 1">正常</span>
          <span v-else>禁用</span>
        </template>
      </el-table-column>
      <el-table-column label="使用状态" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.is_use == 1">使用中</span>
          <span v-else>空闲</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="230" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <div>
            <el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDel(scope.row)">删除</el-button>
          </div>
          <!-- <div style="margin-top:5px;">
            <el-button size="mini" type="primary" @click="handleOpen(scope.row)">打开所有箱子</el-button>
          </div> -->
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30,50,200]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-position="left" label-width="90px">
        <el-form-item label="设备">
          <el-select class="filter-item" v-model="form.device_id" placeholder="请选择设备" style="width: 140px" clearable>
            <el-option v-for="(item, index) in devices" :key="index" :label="item.device_address_and_name" :value="item.device_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="箱子名称">
          <el-input v-model="form.box_name" placeholder="请输入箱子名称" />
        </el-form-item>
        <el-form-item label="锁地址">
          <el-input v-model="form.lock_id" placeholder="请输入锁地址" />
        </el-form-item>
        <el-form-item label="箱子大小">
          <el-radio v-model="form.box_size" label="S" border size="small">小箱</el-radio>
          <el-radio v-model="form.box_size" label="L" border size="small">大箱</el-radio>
        </el-form-item>
        <el-form-item label="禁用状态">
          <el-radio v-model="form.is_active" :label="1" border size="small">正常</el-radio>
          <el-radio v-model="form.is_active" :label="0" border size="small">禁用</el-radio>
        </el-form-item>
        <el-form-item label="使用状态">
          <el-radio v-model="form.is_use" :label="0" border size="small">空闲</el-radio>
          <el-radio v-model="form.is_use" :label="1" border size="small">使用中</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import { excel2Json } from "@/utils/excel";

export default {
  data() {
    return {
      devices: [],
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        school_id: "",
        device_id: "",
        is_active: "",
      },
      dialogVisible: false,
      btnLoading: false,
      form: {
        id: "",
        school_id: "",
        device_id: "",
        box_name: "",
        lock_id: "",
        box_size: "S",
        is_use: 0,
        is_active: 1
      },
      ids: [],
      importBtnLoading: false,
    };
  },
  created() {
    this.getList()
    this.getDeviceList()
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getDeviceList();
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          school_id: "",
          device_id: "",
          box_name: "",
          lock_id: "",
          box_size: "S",
          is_use: 0,
          is_active: 1
        };
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/box/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getDeviceList() {
      request({
        url: "/api/backend/device/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
        }
      }).then(response => {
        this.devices = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleAdd() {
      this.dialogVisible = true;
    },
    handleEdit(item) {
      this.form = {
        id: item.id,
        school_id: item.school_id,
        device_id: item.device_id,
        box_name: item.box_name,
        lock_id: item.lock_id,
        box_size: item.box_size,
        is_use: item.is_use,
        is_active: item.is_active,
      };
      this.dialogVisible = true;
    },
    handleBatchOption(option, status) {
      this.$confirm("确定要提交该操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/box/batchOption",
          method: "post",
          data: {
            ids: this.ids,
            option: option,
            status: status,
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleDel(item) {
      this.$confirm("确定要删除该箱子吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/box/del",
          method: "post",
          data: {
            id: item.id
          }
        }).then(() => {
          // 删除最后一条数据时无数据问题
          this.list.length <= 1 ? this.listQuery.page-- : false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleSelectionChange(val) {
      let ids = [];
      val.forEach(element => {
        ids.push(element.id);
      });
      this.ids = ids;
    },
    saveData() {
      this.form.school_id = this.school_id;
      if (!this.form.school_id) {
        this.$message({
          type: "warning",
          message: "请先选择学校"
        });
        return;
      }
      if (!this.form.device_id) {
        this.$message({
          type: "warning",
          message: "请选择设备"
        });
        return;
      }
      if (!this.form.box_name) {
        this.$message({
          type: "warning",
          message: "请输入箱子名称"
        });
        return;
      }
      if (!this.form.lock_id) {
        this.$message({
          type: "warning",
          message: "请输入锁地址"
        });
        return;
      }
      if (!this.form.box_size) {
        this.$message({
          type: "warning",
          message: "请选择箱子大小"
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/box/save",
        method: "post",
        data: this.form
      })
        .then(() => {
          this.dialogVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    handleDownload() {
      let a = document.createElement("a");
      a.href = '/static/餐柜箱子模板.xlsx'
      a.click()
    },
    // 导入excel
    handleImport(file) {
      let data = [];
      // element-ui的file做了封装，file.raw才是File对象
      file = file.raw;
      excel2Json(file)
        .then(excelData => {
          // 处理业务
          if (excelData.length <= 0) {
            this.$message.error("数据不能为空");
            return;
          }
          excelData.forEach(element => {
            if (element["序号"]) {
              data.push({
                index: element["序号"],
                school_id: this.school_id,
                device_id: element["设备编号"],
                box_name: element["箱子名称"],
                lock_id: element["锁地址"],
                box_size: element["箱子大小"],
              })
            }
          });
          this.importBtnLoading = true
          request({
            url: "/api/backend/box/saveBatch",
            method: "post",
            data: {
              data: data,
            }
          }).then(response => {
            this.importBtnLoading = false
            this.getList()
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          }).finally(()=>{
            this.importBtnLoading = false
          });
        })
        .catch(e => {
          this.$message.error("文件格式出错");
        });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
